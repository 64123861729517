@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');

body {
  margin:0;
  padding:0;
  font-family: 'Inter', sans-serif!important;
}

.primary{
    color:#19DB8A!important;
}
.primarybg{
    background-color:#19DB8A;
}

a{
  color:#19DB8A!important;
}

.ant-select{
  min-height: 36px !important;
  height: auto!important;
}


.ant-drawer{
  z-index: 999!important;
}

.ant-modal{
  z-index: 1000!important;
}

.ant-btn:not(.ant-popconfirm-buttons) {
  justify-content: center;
  display: flex!important;
  align-items: center!important;
}